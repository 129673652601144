<!--
 * @Description: AKJERRT
 * @Date: 2022-08-18 14:00:16
 * @LastEditTime: 2022-09-30 10:04:00
 * @FilePath: \linkgap-front\src\views\aarget_budget\proSWSTarget.vue
-->
<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <a-row :gutter="8">
        <a-col :span="6">
          <div style="border: 1px solid #e8e8e8; background: white; height: 45rem; width: 270px; overflow-y: auto">
            <a-tree
              :treeData="pro"
              v-if="pro.length > 0"
              :load-data="onLoadData"
              :expanded-keys="expandedKeys"
              :auto-expand-parent="autoExpandParent"
              @expand="onExpand"
              @select="handleSelect"
            >
            </a-tree>
          </div>
        </a-col>
        <a-col :span="6" v-if="res_data.cateId && flag">
          <div style="border: 1px solid #e8e8e8; background: white; height: 45rem; width: 270px; overflow-y: auto">
            <a-tree
              style="width: 250px"
              v-if="depts.length"
              :dropdownStyle="{ maxHeight: '400px', maxWidth: '250px', overflow: 'auto' }"
              :tree-data="depts"
              @select="handleChangeDept"
              placeholder="部门"
              defaultExpandAll
            >
            </a-tree>
          </div>
        </a-col>
        <a-col :span="10">
          <a-spin :spinning="spinningStatus">
            <div style="height: 43.75rem">
              <p class="title-year" v-if="res_data.targetId">
                <a-date-picker
                  mode="year"
                  v-model="date_yaer"
                  :open="dataopen"
                  format="YYYY"
                  @openChange="openChangeYear"
                  @panelChange="panelChange"
                />
                <a-badge status="success" :text="`全年目标 ${temp.targetAmount} （万元）`" />
                <span></span>
              </p>
              <template v-if="res_data.targetId">
                <a-card v-for="(item, index) in temp.quarterList" :key="index" :title="`第${index + 1}季度`">
                  <a slot="extra" href="#">{{ item.targetAmount }} 万元</a>
                  <a-card-grid style="text-align: center" v-for="(item2, index2) in item.monthList" :key="index2">
                    {{ item2.targetDate }}月
                    <a-input
                      prefix="￥"
                      suffix="万元"
                      v-model="temp.quarterList[index].monthList[index2].targetAmount"
                    ></a-input>
                  </a-card-grid>
                </a-card>
              </template>
              <br />
              <a-row type="flex" justify="center" v-if="res_data.targetId">
                <a-button @click="submit" type="primary">提交</a-button>
                {{ sum }}
              </a-row>
            </div>
          </a-spin>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>
<script>
const qs = require('qs')

export default {
  name: 'salesTarget',
  inject: ['reload'],
  data() {
    return {
      spinning: false,
      dataList: [],
      depts: [],
      expandedKeys: [0],
      defaultExpandedKeys: [],
      autoExpandParent: true,
      data: [],
      pro: [],
      spinningStatus: false,
      temp: {
        cateId: 0, // cateId
        cateName: '', //分类NAME
        quarterList: [
          //季度目标列表
          {
            monthList: [
              //月度目标列表
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '01', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '02', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '03', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
            ],
            targetAmount: 0, //季度销售目标金额
            targetDate: '', //销售目标年份
            targetType: 'Q1',
          },
          {
            monthList: [
              //月度目标列表
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '04', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '05', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '06', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
            ],
            targetAmount: 0, //季度销售目标金额
            targetDate: '', //销售目标年份
            targetType: 'Q2',
          },
          {
            monthList: [
              //月度目标列表
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '07', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '08', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '09', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
            ],
            targetAmount: 0, //季度销售目标金额
            targetDate: '', //销售目标年份
            targetType: 'Q3',
          },
          {
            monthList: [
              //月度目标列表
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '10', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '11', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
              {
                targetAmount: 0, //月度销售目标金额
                targetDate: '12', //销售目标月份
                targetType: 'M', // 销售目标类型
              },
            ],
            targetAmount: 0, //季度销售目标金额
            targetDate: '', //销售目标年份
            targetType: 'Q4',
          },
        ],
        targetAmount: 0, // 全年销售目标金额
        targetDate: '', //销售目标年份
        targetId: 0, // 目标对象ID
        targetName: '', // 目标对象NAME
        targetType: 'Y',
      },
      dataopen: false, // 默认是否打开弹框
      date_yaer: moment(),
      lable: '',
      cateName: '',
      flag: true,
      res_data: {
        targetDate: moment().format('YYYY'),
        targetId: '',
        cateId: '',
      },
    }
  },
  computed: {
    sum() {
      let yearTotal = 0
      let Q1Total = 0
      let Q2Total = 0
      let Q3Total = 0
      let Q4Total = 0
      this.temp.quarterList.forEach((item, index) => {
        item.monthList.forEach((item2, index2) => {
          yearTotal += Number(item2.targetAmount)
        })
        item.monthList.forEach((item3, index3) => {
          if (item3.targetDate === '01' || item3.targetDate === '02' || item3.targetDate === '03') {
            Q1Total += Number(item3.targetAmount)
          }
        })
        item.monthList.forEach((item4) => {
          if (item4.targetDate === '04' || item4.targetDate === '05' || item4.targetDate === '06') {
            Q2Total += Number(item4.targetAmount)
          }
        })
        item.monthList.forEach((item5, index3) => {
          if (item5.targetDate === '07' || item5.targetDate === '08' || item5.targetDate === '09') {
            Q3Total += Number(item5.targetAmount)
          }
        })
        item.monthList.forEach((item6, index3) => {
          if (item6.targetDate === '10' || item6.targetDate === '11' || item6.targetDate === '12') {
            Q4Total += Number(item6.targetAmount)
          }
        })
      })
      this.temp.quarterList[0].targetAmount = Q1Total
      this.temp.quarterList[1].targetAmount = Q2Total
      this.temp.quarterList[2].targetAmount = Q3Total
      this.temp.quarterList[3].targetAmount = Q4Total
      this.temp.targetAmount = yearTotal
    },
  },
  async created() {
    await this.getDeptList()
    this.defaultExpandedKeys = this.depts // 默认展开全部节点
    this.treeOnLoad = false
    await this.getProList()
  },

  methods: {
    getDeptList() {
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        const data = res.body
        this.depts = data

        // this.buildtree(data, this.depts, 0)
      })
    },
    getProList() {
      this.axios.get('/api/product/category/list/0/2').then((res) => {
        this.pro = res.body || []
      })
    },
    onLoadData(treeNode) {
      let id = treeNode.eventKey
      return new Promise(
        (resolve) => {
          this.axios.get('/api/product/category/list/' + id + '/2').then((res) => {
            treeNode.dataRef.children = res.body

            this.pro = [...this.pro]
          })
          resolve()
        },
        (reject) => {}
      )
    },
    init() {
      this.spinningStatus = true

      this.axios
        .get(`/api/dealer/salesTargets/firmTargetsProCate/selectById/?${qs.stringify(this.res_data)}`)
        .then(({ body, code, message }) => {
          if (code === 200 && body) {
            console.log(JSON.parse(JSON.stringify(body)), '获取部门销售目标')
            this.spinningStatus = false
            this.temp = body
          } else {
            this.spinningStatus = false

            this.temp = {
              cateId: 0, // cateId
              cateName: '', //分类NAME
              quarterList: [
                //季度目标列表
                {
                  monthList: [
                    //月度目标列表
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '01', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '02', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '03', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                  ],
                  targetAmount: 0, //季度销售目标金额
                  targetDate: '', //销售目标年份
                  targetType: 'Q1',
                },
                {
                  monthList: [
                    //月度目标列表
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '04', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '05', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '06', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                  ],
                  targetAmount: 0, //季度销售目标金额
                  targetDate: '', //销售目标年份
                  targetType: 'Q2',
                },
                {
                  monthList: [
                    //月度目标列表
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '07', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '08', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '09', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                  ],
                  targetAmount: 0, //季度销售目标金额
                  targetDate: '', //销售目标年份
                  targetType: 'Q3',
                },
                {
                  monthList: [
                    //月度目标列表
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '10', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '11', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                    {
                      targetAmount: 0, //月度销售目标金额
                      targetDate: '12', //销售目标月份
                      targetType: 'M', // 销售目标类型
                    },
                  ],
                  targetAmount: 0, //季度销售目标金额
                  targetDate: '', //销售目标年份
                  targetType: 'Q4',
                },
              ],
              targetAmount: 0, // 全年销售目标金额
              targetDate: '', //销售目标年份
              targetId: 0, // 目标对象ID
              targetName: '', // 目标对象NAME
              targetType: 'Y',
            }
          }
        })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(ID, event) {
      let key = event.selectedNodes[0].data.props.dataRef.key
      if (String(key).slice(0, 2) === 'DQ') {
        this.res_data.targetId = ''
        return false
      }
      console.log(JSON.parse(JSON.stringify(event.selectedNodes[0].data.props.dataRef)))
      this.lable = event.selectedNodes[0].data.props.title
      this.res_data.targetId = event.selectedNodes[0].data.props.id
      this.temp.targetName = this.lable
      this.init()
    },
    handleSelect(value, event) {
      this.res_data.cateId = value.join('')
      this.cateName = event.selectedNodes[0].data.props.title
      this.res_data.targetId = ''
    },
    openChangeYear(status) {
      if (status) {
        this.dataopen = true
      } else {
        this.dataopen = false
      }
    },
    // 选择年之后 关闭弹框
    panelChange(date) {
      this.date_yaer = date
      this.res_data.targetDate = date.format('YYYY')
      this.temp.targetName = this.lable
      this.dataopen = false
      this.init()
    },
    submit() {
      this.temp.quarterList.forEach((item, index) => {
        item.targetDate = this.res_data.targetDate
      })
      this.temp.targetDate = this.res_data.targetDate
      this.temp.targetId = this.res_data.targetId
      this.temp.targetName = this.lable
      this.temp.cateId = this.res_data.cateId
      this.temp.cateName = this.cateName
      console.log('提交', JSON.parse(JSON.stringify(this.temp)))
      if (this.temp.targetAmount < 1) {
        this.$message.info(`请输入${this.temp.targetDate}目标值`)
        return false
      }
      if (!this.temp.targetDate) {
        this.$message.info(`请选择年份`)
        return false
      }
      let status = true
      // this.temp.quarterList.forEach((item) => {
      //   item.monthList.forEach((item2) => {
      //     if (item2.targetAmount <= 0) {
      //       this.$message.info(`请输入${item2.targetDate}月目标值`)
      //       status = false
      //     }
      //   })
      //   return status
      // })
      if (status) {
        this.axios
          .post('/api/dealer/salesTargets/firmTargetsProCate/save', this.temp)
          .then(({ body, code, message }) => {
            if (code === 200) {
              this.$message.success(message)
              this.reload()
            }
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './components/index.scss';

.options-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.option-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
</style>